import { Link, useLocation } from 'react-router-dom';
import TokenPrice from './widgets/TokenPrice';
import { WalletDisconnectButton, WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { useWalletMultiButton } from '@solana/wallet-adapter-base-ui';
import { WalletName } from '@solana/wallet-adapter-base';
import { useCallback, useEffect, useState } from 'react';
import { Wallet, useConnection, useWallet } from '@solana/wallet-adapter-react';
import Modal, { useModal } from './widgets/Modal';
import toast, { Toaster } from 'react-hot-toast';

export const SiteLogo = () => {
  return (
      <div className='my-auto'>
        {/* <img src="/logofull.svg" className="h-10 ml-4  hidden md:inline-block" alt="" /> */}
        <img src="/logo.svg" className="h-12" alt="" />
      </div>
  );
}


export const Menu = () => {
  const location = useLocation(); // Get the current path
  
  const getClass = (path: string) => {
    // If the current path matches the given path, apply a different style
    return location.pathname === path 
      ? "text-2xl align-middle text-[#ee4300] font-bold" // Active style
      : "text-2xl align-middle text-[#ee4300]/40 font-bold"; // Inactive style
  };

  return (
    <div className="gap-3 my-auto pl-6 hidden md:flex ">
      <Link to="/" className={getClass("/")}>Home</Link>
      <Link to="/swap" className={getClass("/swap")}>Swap</Link>
      <Link to="/bridge" className={getClass("/bridge")}>Bridge</Link>
      <div className="relative inline-flex items-center">
        <span className={getClass("/staking")}>Staking</span>
        <div className="absolute inline-flex items-center justify-center text-xs h-4 bg-[gray]/30 text-[gray] p-2 rounded -top-2 -end-3 drop-shadow-2xl">
          soon!
        </div>
      </div>
      <Link to="/tokens" className={getClass("/tokens")}>Tokens</Link>
    </div>
  );
};

export const Hamburger = () => {
  return (
    <div className="px-4 border-r border-black/40 my-auto block md:hidden"><img src="menu.svg" className='my-auto' alt="" /></div>
  )
}

export const Header = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      console.log("hey")
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


    return (
        <div className={`${scrolled ? 'backdrop-blur bg-[black]/10 border-b border-[white]/5' : 'bg-transparent border-b border-transparent'} flex  py-5     h-[80px] px-6 gap-3 fixed w-full z-[100]`}>
          
             <Link to="/"> <SiteLogo/></Link>
            {/* <div className='my-auto h-fit text-2xl bg-gradient-to-r from-[#ed6300] to-[#ef3500] bg-clip-text text-transparent font-bold font-black font-bold mt-1'>Swap</div> */}
          {/* <Menu/> */}
          <div className="px-6 flex my-auto h-fit hidden md:flex">

          <Link className='px-2  text-[white]/40 p-1 py-2 flex cursor-pointer hover:bg-gradient-to-r from-[#ed6300]/10 font-semibold to-[#ef3500]/10 rounded-lg p-1 ease-in-out duration-300 transition rounded-lg hover:text-[white]/80 cursor-pointer' to="/swap">  <div className='px-2'>Swap</div> </Link>
          <Link className='px-2 hover:bg-gradient-to-r from-[#ed6300]/10 font-semibold to-[#ef3500]/10 text-[white]/40 p-1 py-2 flex rounded-lg hover:text-[white]/80 cursor-pointer' to="/bridge">  <div className='px-2'>Bridge</div> </Link>
          <div className='px-2  hover:bg-gradient-to-r from-[#ed6300]/10 font-semibold to-[#ef3500]/10 text-[white]/40 p-1 py-2 flex rounded-lg hover:text-[white]/80 cursor-pointer'> <div className='px-2'>Staking</div> </div>
          <div className='px-2 hover:bg-gradient-to-r from-[#ed6300]/10 font-semibold to-[#ef3500]/10 rounded-lg hover:text-[white]/80 cursor-pointer text-[white]/40 p-1 py-2 flex'> <div className='px-2'>More</div> </div>
          </div>
            <div className="grow"></div>
            {/* <TokenPrice/> */}
                     <div className="h-fit bg-[#2e164c]/70 py-2 px-3 text-primary rounded-full space-x-3 cursor-pointer hover:bg-[#2e164c]/90 border border-[#5c2e9a]/80 drop-shadow-2xl text-sm shadow-lg shadow-[#5c2e9a]/30 hover:shadow-lg hover:shadow-[#2e164c]/40"> 
          <img src="/solana.png" className="inline-block h-5" alt="" />
        <span className="font-semibold hidden md:inline-block">SOL</span>
      </div>
      {/* <WalletMultiButton />
                    <WalletDisconnectButton /> */}
                    <CustomConnectButton/>
      
      {/* <Modal isOpen={true} onClose={() => {}}>hello</Modal> */}
        </div>
    );
}

function CustomConnectButton() {
  const [walletModalConfig, setWalletModalConfig] = useState<Readonly<{
      onSelectWallet(walletName: WalletName): void;
      wallets: Wallet[];
  }> | null>(null);
  
  const { publicKey } = useWallet();
  const [modal, setModal] = useState(false)
  const { buttonState, onConnect, onDisconnect, onSelectWallet } = useWalletMultiButton({
      onSelectWallet: setWalletModalConfig,
  });
  let label;
  let buttonStyle;
  switch (buttonState) {
      case 'connected':
          let tmpLbl = publicKey? publicKey.toBase58() : "..................................."
          label = publicKey? tmpLbl.substring(0,8) + "..."+ tmpLbl.substring(tmpLbl.length-8, tmpLbl.length) : ''
          buttonStyle = "bg-[#ef3900]/30 hover:bg-[#ef3900]/50 drop-shadow-2xl  border border-[#ef3900]/30 shadow-lg shadow-[#ef3900]/20 hover:shadow-lg hover:shadow-[#ef3900]/30 text-[#ef3900]";
          break;
      case 'connecting':
          label = 'Connecting';
          buttonStyle = 'bg-[#ef3900]/30 hover:bg-[#ef3900]/50 drop-shadow-2xl  border border-[#ef3900]/30 shadow-lg shadow-[#ef3900]/20 hover:shadow-lg hover:shadow-[#ef3900]/30 text-[#ef3900]';
          break;
      case 'disconnecting':
          label = 'Disconnecting';
          buttonStyle = 'bg-[#1bc870]/30 hover:bg-[#1bc870]/50 drop-shadow-2xl  border border-[#1bc870]/30 shadow-lg shadow-[#1bc870]/20 hover:shadow-lg hover:shadow-[#1bc870]/30 text-[#1bc870]';
          break;
      case 'has-wallet':
          label = 'No wallet';
          buttonStyle = 'bg-[red]/30 hover:bg-[red]/50 drop-shadow-2xl  border border-[red]/30 shadow-lg shadow-[red]/20 hover:shadow-lg hover:shadow-[red]/30 text-[red]';
          break;
      case 'no-wallet':
          label = 'Connect';
          buttonStyle = 'bg-[#1bc870]/30 hover:bg-[#1bc870]/50 drop-shadow-2xl  border border-[#1bc870]/30 shadow-lg shadow-[#1bc870]/20 hover:shadow-lg hover:shadow-[#1bc870]/30 text-[#1bc870]';
          break;
  }
  const handleClick = useCallback(() => {
      switch (buttonState) {
          case 'connected':
              return onDisconnect;
          case 'connecting':
          case 'disconnecting':
              break;
          case 'has-wallet':
              return onConnect;
          case 'no-wallet':
              if (onSelectWallet) {onSelectWallet()}
              break;
      }
  }, [buttonState, onDisconnect, onConnect, onSelectWallet]);
  const {showModal, hideModal} = useModal();

  useEffect(() => {
    if (publicKey != null) {
      toast.success("Wallet connected!",{position:'bottom-right'});
    }
  }, [publicKey])

  useEffect(() => {
    if (walletModalConfig != null) {
      showModal(<div className='flex-col space-y-3'>
      <span className="text-3xl">Select a wallet</span>
    <div className="py-8 space-y-4">
    {walletModalConfig.wallets.map((wallet) => (
        <button
            key={wallet.adapter.name}
            onClick={() => {
                walletModalConfig.onSelectWallet(wallet.adapter.name);
                hideModal();
                setWalletModalConfig(null);
            }}
            className='p-3 block w-full flex hover:bg-[white]/10 rounded-lg space-x-3'
        >
            <img src={wallet.adapter.icon} className='w-8 h-8' alt="" />
            <span className='my-auto'>{wallet.adapter.name}</span>
        </button>
    ))}
    </div>
    </div>)
    }
  }, [walletModalConfig])
  return (
      <>
            <button className={`h-fit relative inline-flex items-center px-3 py-2 text-sm font-medium text-center space-x-3 backdrop-blur cursor-pointer rounded-full ${buttonStyle}`} onClick={handleClick} disabled={buttonState === 'connecting' || buttonState === 'disconnecting'}> 
      
      <span className="font-bold " >{label}</span>
      {!publicKey && buttonState != "connecting" && <div className="absolute inline-flex items-center justify-center duration-500 animate-ping w-4 h-4  bg-[#1bc870] blur-[1px]   rounded-full -top-1 -end-1 drop-shadow-2xl"></div>}</button>   
      {publicKey && <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" className="h-6 my-auto text-[white] cursor-pointer" fill="white" onClick={onDisconnect}>
  <path d="M868 732h-70.3c-4.8 0-9.3 2.1-12.3 5.8-7 8.5-14.5 16.7-22.4 24.5a353.84 353.84 0 0 1-112.7 75.9A352.8 352.8 0 0 1 512.4 866c-47.9 0-94.3-9.4-137.9-27.8a353.84 353.84 0 0 1-112.7-75.9 353.28 353.28 0 0 1-76-112.5C167.3 606.2 158 559.9 158 512s9.4-94.2 27.8-137.8c17.8-42.1 43.4-80 76-112.5s70.5-58.1 112.7-75.9c43.6-18.4 90-27.8 137.9-27.8 47.9 0 94.3 9.3 137.9 27.8 42.2 17.8 80.1 43.4 112.7 75.9 7.9 7.9 15.3 16.1 22.4 24.5 3 3.7 7.6 5.8 12.3 5.8H868c6.3 0 10.2-7 6.7-12.3C798 160.5 663.8 81.6 511.3 82 271.7 82.6 79.6 277.1 82 516.4 84.4 751.9 276.2 942 512.4 942c152.1 0 285.7-78.8 362.3-197.7 3.4-5.3-.4-12.3-6.7-12.3zm88.9-226.3L815 393.7c-5.3-4.2-13-.4-13 6.3v76H488c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h314v76c0 6.7 7.8 10.5 13 6.3l141.9-112a8 8 0 0 0 0-12.6z"/>
</svg>}
   
    
      </>
  );
}

import { useState, useEffect } from 'react';

const useKeyboardOpen = () => {
    const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);

    useEffect(() => {
        const initialInnerHeight = window.innerHeight;

        const handleResize = () => {
            // Define a threshold (e.g., 150px) which, when the window height decreases by this or more, assumes the keyboard is open
            const threshold = 150;
            const currentInnerHeight = window.innerHeight;
            const isKeyboard = initialInnerHeight - currentInnerHeight > threshold;
            setIsKeyboardOpen(isKeyboard);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return isKeyboardOpen;
};

export default useKeyboardOpen;

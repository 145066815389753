import { FC, useEffect, useState } from "react";

interface PriceImpactProps {
    priceImpact: string
}

export const PriceImpact : FC<PriceImpactProps> = ({priceImpact}) => {
    const [realPriceImpact, setRealPriceImpact] = useState(0);
    
    useEffect(() => {
        let rpi = 0;
        rpi = parseFloat(priceImpact)*100;
        setRealPriceImpact(parseFloat(priceImpact)*100);
    },[priceImpact])

    return (
        <div>
            <div className={`${realPriceImpact < 10 ? 'text-[green]' : 'text-[red]'} font-bold`}>{realPriceImpact.toFixed(2)}%</div>
        </div>
    );
}

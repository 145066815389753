export const LimitOrders = () => {
    return (
        <div className="w-full justify-center">
            <div className="mx-auto text-center ">
                        <img src="/algo.png" className="h-32 mx-auto my-8" alt="" />
                        <span className='text-8xl mx-auto bg-gradient-to-r from-[#ed6300] to-[#ef3500] bg-clip-text text-transparent font-bold text-center md:text-normal font-black'>Coming soon!</span>
                    </div>
        </div>
    );
}

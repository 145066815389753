import React, { useState, useEffect } from 'react';
import { Connection, PublicKey } from '@solana/web3.js';
import { getAccount, getAssociatedTokenAddress, MintLayout } from '@solana/spl-token';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';

interface TokenBalanceProps {
  token: Token;
  setValue: (val:string) => void 
}

const TokenBalance: React.FC<TokenBalanceProps> = ({ token, setValue }) => {
  const [balance, setBalance] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const connection = useConnection();
  const { publicKey } = useWallet();
  
  useEffect(() => {
    const fetchBalance = async () => {
      if (publicKey) {
        const NETWORK = 'https://api.mainnet-beta.solana.com';
      const SOL_MINT_ADDRESS = 'So11111111111111111111111111111111111111112'; 
      const walletPublicKey = new PublicKey(publicKey.toBuffer());
      setLoading(true);
      console.log(token.address)
      
      try {
        let tmpBalance;
        if (token.address == SOL_MINT_ADDRESS) {
          tmpBalance = await connection.connection.getBalance(walletPublicKey);
          setBalance(parseFloat((tmpBalance/10**9).toString()));
        } else {
          const mintPublicKey = new PublicKey(token.address);
          const tokenAccountAddress = await getAssociatedTokenAddress(mintPublicKey, walletPublicKey);
          const tokenAccountInfo = await getAccount(connection.connection, tokenAccountAddress);
          const mintInfo = await connection.connection.getAccountInfo(mintPublicKey);
          if (!mintInfo) throw new Error('Mint information not found');
          const mintData = MintLayout.decode(mintInfo.data);
          const decimals = mintData.decimals;
          tmpBalance = tokenAccountInfo.amount / BigInt(Math.pow(10, decimals));
          setBalance(parseFloat(tmpBalance.toString()));
        }
      } catch (error) {
        setBalance(0);
      }
      setLoading(false);
      }
    };
  
    fetchBalance();
  }, [publicKey, token]);
  

  return (
    <div>
      {publicKey && loading &&
          <div className="text-xs font-normal select-none text-[white]/50 mt-1"> <div className="w-36 h-4 inline-block rounded bg-[white]/10 animate-pulse"></div> </div>
        }
      {loading && !publicKey &&
          <div></div>
        
      }
      {
        !loading && publicKey &&

        <div className="flex">
                  <div className="text-sm font-normal text-[white]/50 mt-1" onClick={() => {if (balance != null) {setValue((balance*0.98).toString())}}}> 
          <img src="/wallet.png" className='h-3 mb-1 inline-block' alt="" /> 
          <span className='my-auto font-semibold hover:underline cursor-pointer px-1'>
            {balance !== null ? `${balance}` : "0"}
          </span>
        </div>
          {balance != null && <div className='h-full pl-3 space-x-3 h-fit my-auto'><div className=' text-[10px] font-bold hover:bg-[#ef3900] hover:text-white  text-[white]/40 my-auto  rounded-lg p-2 bg-[white]/10 inline-block cursor-pointer' onClick={() => setValue(balance.toString())}>max</div><a href="#" className='text-[10px] bg-[white]/10 hover:bg-[#ef3900] hover:text-white font-bold my-auto  rounded-lg p-2 text-[white]/20 inline-block cursor-pointer' onClick={() => setValue((balance*0.50).toString())}>half</a></div>}
        </div>
      }
      
    
    </div>
  );
};

export default TokenBalance;

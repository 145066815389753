import { useEffect, useState } from "react";
import { Link } from "react-router-dom";


export const Home = () => {

    return (

      <div className="grow ">
        
      <div className=" pt-[80px] min-h-screen">
      <div className="mx-auto  max-w-7xl md:py-8 text-[white] space-y-3  z-[1000]" >

      <div className="block w-fit mx-auto md:hidden">
    <img src="/volcano.png" className=" h-72  eruption  duration-300 ease-in-out " alt="" />
  </div>
<div className=" space-y-4 px-4 mx-auto md:px-8  md:py-16 text-center md:text-right flex md:text-left">
<div className="">            <div className="text-4xl font-semibold text-center md:text-right md:text-8xl  pt-8 md:pt-16 pb-2" ><span>Redefining the</span> <span className=" bg-clip-text drop-shadow-xl bg-gradient-to-r from-[#ed6300] to-[#ef3500] text-transparent">Blockchain</span> Experience</div> 
<p className="text-lg font-bold bg-gradient-to-r from-[#ed6300] to-[#ef3500] text-transparent bg-clip-text pb-8 text-center md:text-right ">User-friendly. Useful. Built for you.
</p>
<p className="text-sm pb-8 text-center md:text-right" >Use LavaSwap, our multi-chain DEX aggregator.
</p>
<div className="md:flex md:w-full mx-auto w-fit md:mx-0  space-y-2 md:space-x-2">
<div className="grow"></div>
<Link className="w-full md:w-fit px-3 py-3 rounded-lg border-2 border-[#ed6300]/30 drop-shadow-xl select-none hover:bg-[#ef3900] hover:text-[white] cursor-pointer text-[#ef3600] font-bold whitespace-nowrap text-[#ef3900] hover:text-[white] duration-300 ease-in-out flex transition-all " to={"/swap"} key={"trade"}> <svg viewBox="0 0 48 48" fill="currentColor" className="h-6 mx-2" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.35" d="M42.828 23.1719C41.986 22.3299 32.432 12.7739 29.684 10.0279L32.698 7.0139C33.788 5.9239 33.022 4.0599 31.48 4.0519L20.52 3.9999C19.126 3.9939 17.994 5.1259 18 6.5199L18.052 17.4799C18.06 19.0199 19.924 19.7879 21.014 18.6979L24.028 15.6839C26.776 18.4319 36.33 27.9859 37.172 28.8279C38.734 30.3899 41.266 30.3899 42.828 28.8279C44.39 27.2659 44.39 24.7339 42.828 23.1719Z"></path>
    <path d="M5.17199 24.828C6.01399 25.67 15.568 35.226 18.316 37.9721L15.302 40.9861C14.212 42.0761 14.98 43.9401 16.52 43.9481L27.48 44.0001C28.874 44.0061 30.006 42.8741 30 41.4801L29.948 30.5201C29.94 28.9781 28.076 28.212 26.986 29.3021L23.972 32.3161C21.224 29.5681 11.67 20.014 10.828 19.172C9.26599 17.61 6.73399 17.61 5.17199 19.172C3.60999 20.734 3.60999 23.266 5.17199 24.828Z"></path>
  </svg><div className="">Trade Now</div></Link>

</div>
</div><div className="hidden md:block">
    <img src="/volcano.png" className=" flotating eruption min-w-[450px] duration-300 ease-in-out " alt="" />
  </div>
</div>
</div>
      </div>
      {/* <div className="h-screen bg-[black]/30 backdrop-blur py-[80px] ">
        <div className="mx-auto text-8xl w-fit text-center font-semibold"> <span>Meet our</span> <span className="text-[#ef3900]">platform</span></div>
<div className="md:flex mx-auto w-fit py-[5%]">
<div className="pop-in px-[100px] text-3xl md:text-5xl space-y-8">
<div>Swap</div>
<div>Bridge</div>
</div>
        <div className=" w-fit floating ">
          <div className="bg-[#ef3900] blur-2xl opacity-50 h-[500px]  w-[500px]  rounded-full relative"></div>
        <img src="/coin.png" className="h-[290px] mt-[-400px] ml-[100px] z-[10] relative" alt="" />
        </div>
        <div className="px-[100px] py-[80px] md:py-0 pop-in space-y-8 ml-[-100px]">
<div className="">
<div className="text-3xl md:text-5xl">Farms</div>
<div className="w-72">Staking LBF tokens allows you to earn rewards while helping to secure the network. </div>
</div>
<div className="space-y-4 ">
<div className="text-3xl md:text-5xl">Wallet</div>
<div className="w-72">Staking LBF tokens allows you to earn rewards while helping to secure the network. </div>

</div>
</div>
</div>
      </div> */}
      </div>

    );
}

export default Home;
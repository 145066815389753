import React, { useState, useEffect } from 'react';

const TokenPrice = () => {
  const [price, setPrice] = useState("");
  const tokenUrl = 'https://price.jup.ag/v4/price?ids=6W2373i4UWnMUMZHo6te6SZeoP1QfFZAVQCjKBXiHyuZ';

  useEffect(() => {
    const fetchPrice = async () => {
      try {
        const response = await fetch(tokenUrl);
        const data = await response.json();
        const tokenPrice = data.data['6W2373i4UWnMUMZHo6te6SZeoP1QfFZAVQCjKBXiHyuZ'].price;
        setPrice(tokenPrice.toFixed(8));  // Formats the price to 4 decimal places
      } catch (error) {
        console.error('Failed to fetch token price:', error);
        setPrice('Error');
      }
    };

    fetchPrice();
  }, []);

  return (
    <div className='text-center align-middle flex'>
      <img src="/lbf_logo.svg" alt="Token Logo" className="w-9 mr-2" />
      <span className='my-auto font-bold '>$ {price}</span>
    </div>
  );
};

export default TokenPrice;

import React, { createContext, useContext, useEffect, useState, ReactNode, FC } from 'react';

interface ModalContextType {
  showModal: (content: ReactNode) => void;
  hideModal: () => void;
}

const ModalContext = createContext<ModalContextType | undefined>(undefined);
interface ModalProviderProps {
    children: ReactNode;
}
export const ModalProvider:FC<ModalProviderProps>  = ({ children }) => {
  const [modalContent, setModalContent] = useState<ReactNode>(null);
  const [isVisible, setIsVisible] = useState(false);

  const showModal = (content: ReactNode) => {
    setModalContent(content);
    setIsVisible(true);
  };

  const hideModal = () => {
    setIsVisible(false);
    setModalContent(null);
  };

  return (
    <ModalContext.Provider value={{ showModal, hideModal }}>
      {children}
      {isVisible && (
        <Modal isOpen={isVisible} onClose={hideModal}>
          {modalContent}
        </Modal>
      )}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
}

const Modal: FC<ModalProps> = ({ isOpen, onClose, children }) => {
  // Effect to handle keydown events for 'Escape' key
  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    if (isOpen) {
      window.addEventListener('keydown', handleEscape);
    }

    return () => {
      window.removeEventListener('keydown', handleEscape);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className='w-[100%] h-[100vh] bg-[black]/10 backdrop-blur-sm absolute top-0 left-0 z-[1001]' onClick={(e) => {onClose();}}>
        <div className="relative md:inset-x-0 md:top-[10%]    md:mx-auto w-full h-full md:h-auto md:w-auto md:max-w-xl bg-[#0b0f19]/90 backdrop-blur md:rounded-lg md:shadow-lg shadow-[#0b0f19]/90 md:drop-shadow-xl md:border border-[#ef3900]/20   flex justify-center md:items-center p-4" onClick={(e) => {e.stopPropagation();}}>
      <div className=" max-w-lg w-full">
        <button
          onClick={onClose}
          className="absolute top-0 right-0 mt-4 mr-4"
        >
          Close
        </button>
        {children}
      </div>
    </div>
    </div>
  );
};

export default Modal;

import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { Header } from './Header';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import {toast, Toaster} from 'react-hot-toast';
import { Bridge } from './Bridge';
import { Swap } from './Swap';
import { Tools } from './Tools';
import Home from './Home';
import { Menu } from './Menu';
import { LimitOrders } from './LimitOrders';
import { Staking } from './Staking';
import useKeyboardOpen from './hooks/useKeyboardOpen';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { UnsafeBurnerWalletAdapter } from '@solana/wallet-adapter-wallets';
import {
    WalletModalProvider,
    WalletDisconnectButton,
    WalletMultiButton
} from '@solana/wallet-adapter-react-ui';
import { clusterApiUrl } from '@solana/web3.js';
import { ModalProvider } from './widgets/Modal';
import TokenPrice from './widgets/TokenPrice';

// Default styles that can be overridden by your app
require('@solana/wallet-adapter-react-ui/styles.css');

window.Buffer = window.Buffer || require("buffer").Buffer;

function App() {
  return (
    <div className="h-screen">
      
     
      <Router>
    <Routes>
    <Route path="/" element={ <WrappedApp><Home/></WrappedApp> } />
      <Route path="/swap" element={ <WrappedApp><Swap/></WrappedApp> } />
      <Route path="/bridge" element={<WrappedApp><Bridge /></WrappedApp>} />
      <Route path="/tools" element={<WrappedApp><Tools /></WrappedApp>} />
      <Route path="/limit" element={<WrappedApp><LimitOrders /></WrappedApp>} />
      <Route path="/staking" element={<WrappedApp><Staking /></WrappedApp>} />
    </Routes>
  </Router>
      </div>
       

  );
}


// Utility function to generate a random float within a range
const getRandomFloat = (min, max) => Math.random() * (max - min) + min;

// Function to generate an array of logo configurations
const generateLogos = (rows, cols) => {
  const logos = [];
  const cellWidth = 100 / cols;
  const cellHeight = 100 / rows;

  for (let row = 0; row < rows; row++) {
    for (let col = 0; col < cols; col++) {
      const top = row * cellHeight + getRandomFloat(5, cellHeight / 2 - 5);
      const left = col * cellWidth + getRandomFloat(5, cellWidth / 2 - 5);
      const size = getRandomFloat(5, 15); // Size in rem
      const icon = Math.random() > 0.5 ? '/coin.png' : '/solana.png';
      const rotation = `rotate(${getRandomFloat(0, 360)}deg)`;
      const blurClass = Math.random() > 0.66 ? 'blur-sm' : Math.random() > 0.5 ? 'blur-xl' : 'blur-2xl';
      logos.push({ top: `${top}%`, left: `${left}%`, size, rotation, icon, blurClass });
    }
  }
  return logos;
};

function WrappedApp({ children }: PropsWithChildren<{}>) {
      // The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
      const network = WalletAdapterNetwork.Mainnet;
    
      // You can also provide a custom RPC endpoint.
      const endpoint = useMemo(() => "https://mainnet.helius-rpc.com/?api-key=704c968b-2ce6-4094-9afb-261c897b5887", [network]);
  
      const wallets = useMemo(
          () => [
              /**
               * Wallets that implement either of these standards will be available automatically.
               *
               *   - Solana Mobile Stack Mobile Wallet Adapter Protocol
               *     (https://github.com/solana-mobile/mobile-wallet-adapter)
               *   - Solana Wallet Standard
               *     (https://github.com/anza-xyz/wallet-standard)
               *
               * If you wish to support a wallet that supports neither of those standards,
               * instantiate its legacy wallet adapter here. Common legacy adapters can be found
               * in the npm package `@solana/wallet-adapter-wallets`.
               */
              new UnsafeBurnerWalletAdapter(),
          ],
          // eslint-disable-next-line react-hooks/exhaustive-deps
          [network]
      );

  const isKeyboardOpen = useKeyboardOpen();
  
  const [logos, setLogos] = useState(generateLogos(5, 3));
  const [dimensions, setDimensions] = useState({
    outerWidth: window.outerWidth,
    outerHeight: window.outerHeight
  });

  const updateLogos = () => {
    const { outerWidth, outerHeight } = window;

    if (outerWidth !== dimensions.outerWidth || outerHeight !== dimensions.outerHeight) {
      const rows = Math.floor(outerHeight / 300); // Adjust the divisor for more or fewer rows
      const cols = Math.floor(outerWidth / 300); // Adjust the divisor for more or fewer columns
      console.log(`Rows: ${rows} Cols: ${cols}`)
      setLogos(generateLogos(rows, cols));
      setDimensions({ outerWidth, outerHeight });
    }
  };

  useEffect(() => {
    updateLogos(); // Initial generation

    window.addEventListener('resize', updateLogos);
    return () => window.removeEventListener('resize', updateLogos);
  }, [dimensions]);

  return (
    <div className="h-max bg-gradient-to-r from-[#0d121e]/50 to-[black]">
              <div className="left-0 fixed overflow-hidden w-screen    h-screen z-[-10]">
              {logos.map((logo, index) => (
        <img
          key={index}
          className={`absolute pop-in opacity-50 animate-pulse duration-1000 ease-in-out animate-float hover:border-5 floating ${logo.blurClass}`}
          style={{
            top: logo.top,
            left: logo.left,
            height: `${logo.size}vh`,
            width: `${logo.size}vh`,
            transform: logo.rotation,
            maxWidth: 'calc(100vw - 2rem)', // Ensures logo does not cause horizontal scroll
          }}
          src={logo.icon}
          alt={`Logo ${index + 1}`}
        />
      ))}
              </div>
      
   
        <ModalProvider>
        <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} autoConnect>
                <WalletModalProvider>
                    <Header />
      <div className="">
      {!isKeyboardOpen && <Menu/>}
           <div className=" min-h-screen">{children}</div>
          </div>  
          <div className='pt-16 pb-8 px-8 md:px-16 bg-[black]/20 border-t border-[white]/10'>
                        <div className='block md:hidden '><img src="/logofull.svg" className='h-12 my-4' alt="" /></div>
            <div className='block md:flex pb-4'>
            <div className='md:px-8 py-3'>
              <div className='text-[#ef3900] font-bold text-lg py-4'>Ecosystem</div>
              <a href="/" className='block'>Home</a>
              <a href="/swap" className='block'>Swap</a>
              <a href="/bridge" className='block'>Bridge</a>
              <a href="/staking" className='block'>Staking</a>
            </div>
            <div className='md:px-8 py-3'>
              <div className='text-[#ef3900] font-bold text-lg py-4'>Developers</div>
              <a href="https://lavaburnfinance.gitbook.io/" className='block'>Whitepaper</a>
              <a href="https://github.com/lavaburnfinance" className='block'>GitHub</a>
              <a href="https://leitesv.dev/" className='block'>leitesv</a>
              <a href="https://docs.lavaburn.finance/" className='block'>Documentation</a>
            </div>
            <div className='md:px-8 py-3'>
              <div className='text-[#ef3900] font-bold text-lg py-4'>Support</div>
              <a href="https://t.me/Lavaburn_financeLBF" className='block'>Telegram</a>
              <a href="https://x.com/lavaburnfinance" className='block'>X</a>
              <a href="https://t.me/lavaburn_announcement/" className='block'>Announcements</a>
            </div>
            <div className="grow"></div>
            <div className='hidden md:block'><img src="/logofull.svg" className='h-12 my-4' alt="" /></div>
            </div>
            <div>
            <div className="space-x-3 md:px-8 flex py-6">
          <img src="/telegram.png" className="w-8 cursor-pointer" onClick={()=> window.open("https://t.me/Lavaburn_financeLBF", "_blank")} alt="" />
          <img src="/x.png" onClick={()=> window.open("https://x.com/lavaburnfinance", "_blank")} className="w-8 cursor-pointer" alt="" />
        </div>
            </div>
            <div className="border-t border-[white]/10 py-8 mb-8 flex space-x-3">
              <div className="grow hidden md:block"></div>
            <TokenPrice/>
            <div className="grow block md:hidden"></div>
            <Link className='rounded-full  font-bold  bg-gradient-to-r from-[#ed6300] to-[#ef3500] hover:bg-gradient-to-l hover:from-[#ed6300] hover:to-[#ef3500]  cursor-pointer transition-all ease-in duration-300 shadow-lg shadow-[#ef3900]/30 hover:shadow-lg hover:shadow-[#ef3900] py-2 px-3 space-x-3 flex' to="https://jup.ag/swap/SOL-LBF_6W2373i4UWnMUMZHo6te6SZeoP1QfFZAVQCjKBXiHyuZ"><div>Buy LBF</div> <img src="/arrow.svg" className='h-3 mt-1' alt="" /></Link>
            </div>
 
          </div>
          <Toaster/>
                </WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
        </ModalProvider>
    </div>
  );
}


export default App;

import { Link, useLocation } from "react-router-dom";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import TokenPrice from "./widgets/TokenPrice";

export const Menu = () => {
  const location = useLocation();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null); 
  const isSelected = (path: string) => location.pathname === path;

  const menuItems = [
    { path: "/", icon: "home", label: "Home" },
    { path: "/swap", icon: "swap", label: "Swap", tag:"new!" },
    { path: "/bridge", icon: "bridge", label: "Bridge", tag:"new!" },
    { path: "#", icon: "staking", label: "Staking", tag:"soon" },
    // { path: "#", icon: "wallet", label: "Wallet", tag:"soon" }
  ];

  const [visibleItems, setVisibleItems] = useState([]);
  const [hiddenItems, setHiddenItems] = useState([]);

  useLayoutEffect(() => {
    const handleResize = () => {
      // Define how many items should be visible based on the screen width
      const screenSize = window.innerWidth;
      let visibleCount;
      if (screenSize < 600) {
        visibleCount = 6; // smaller screens show fewer items
      } else if (screenSize < 960) {
        visibleCount = 6; // medium screens
      } else {
        visibleCount = 6; // larger screens show more items
      }

      setVisibleItems(menuItems.slice(0, visibleCount));
      setHiddenItems(menuItems.slice(visibleCount));

    };
    
    // Call the function initially and also add an event listener on window resize
    handleResize();
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="w-full flex md:hidden bg-[black]/10 border-t md:border-t-0 md:border-r border-[white]/5 backdrop-blur-2xl px-4 flex-col fixed  flex-row md:flex z-[100] bottom-0 h-fit">
      <div className="flex w-fit mx-auto md:w-full md:mx-0 md:flex">
        {visibleItems.map(item => (
          <Link to={item.path} key={item.label}
            className={`py-3 cursor-pointer px-4 block ${
              isSelected(item.path)
                ? 'border-x border-[white]/5 font-bold text-[#ef3900]'
                : 'hover:bg-[white]/10 font-bold text-[white]/80'
            }`}>
            <div className="">
            <img className="w-6 h-6 mx-auto" src={`/${item.icon}.svg`}/>
            <div className="text-xs">{item.label}</div>
            {item.tag == "soon" && 
            <span className={`hidden md:inline-block rounded-lg p-1 text-xs bg-[white]/10`}>{item.tag}</span>
            }
                        {item.tag == "new!" && 
            <span className={`hidden md:inline-block rounded-lg p-1 text-xs bg-[#ef3900] text-[white]`}>{item.tag}</span>
            }
            </div>
          </Link>
        ))}
        {hiddenItems.length > 0 && (
          <div className="inline-block">
            <button className={`px-2 text-[#ef3900] rounded-lg text-3xl font-bold align-middle ${
              isMenuOpen
                ? 'bg-gradient-to-r from-[#ed6300]/40 to-[#ef3500]/40 font-bold text-[#ef3900]'
                : 'hover:bg-[white]/10 font-bold text-[#ef3900] rounded-lg'
            }`} onClick={() => setMenuOpen(!isMenuOpen)}>
              <span className="my-auto">...</span>
            </button>
            {isMenuOpen && (
              <div className="absolute right-0 bottom-[83px] bg-black/80 backdrop-blur shadow-lg rounded-lg p-3 md:hidden " ref={menuRef}>
                {hiddenItems.map(item => (
                  <Link to={item.path} key={item.label}
                    className="block py-2 px-4 rounded hover:bg-gray-100/30"
                    onClick={() => setMenuOpen(false)}>
                    <img className="w-6 h-6 inline-block mx-3" src={`/${item.icon}.svg`}/>
                    {item.label}
                  </Link>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
      {/* <div className="bg-[#0e1421] rounded-lg p-4 text-white hidden md:block">
        Your portfolio
      </div> */}
      <div className="grow"></div>
      <div className="hidden md:block">
        <div className="space-x-3 flex py-3">
          <img src="/telegram.png" className="w-8 cursor-pointer" onClick={()=> window.open("https://t.me/Lavaburn_financeLBF", "_blank")} alt="" />
          <img src="/x.png" onClick={()=> window.open("https://x.com/lavaburnfinance", "_blank")} className="w-8 cursor-pointer" alt="" />
        </div>
        <TokenPrice />
        <div className='text-[white]/50 text-xs'>Made with ❤️ by <a href="https://leitesv.dev/">leitesv</a></div>
      </div>
    </div>
  );
};

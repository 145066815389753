import React, { FC, useEffect, useState } from 'react';
import TokenBalance from './widgets/TokenBalance';
import { Settings } from './resources/Icons';
import { Wallet, useConnection, useWallet } from '@solana/wallet-adapter-react';
import Modal, { ModalProvider, useModal } from './widgets/Modal';
import { PriceImpact } from './widgets/PriceImpact';
import InfiniteScroll from 'react-infinite-scroll-component';
import { VersionedTransaction } from '@solana/web3.js';
import { WalletName } from '@solana/wallet-adapter-base';
import { useWalletMultiButton } from '@solana/wallet-adapter-base-ui';


interface TokenListProps {
  onSelectToken: (token:Token) => void
}

const TokenList:FC<TokenListProps> = ({onSelectToken}) => {
  const [tokens, setTokens] = useState([]);
  const [visibleTokens, setVisibleTokens] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const itemsPerPage = 10;

  useEffect(() => {
    const fetchTokens = async () => {
      const response = await fetch('https://token.jup.ag/strict');
      const data = await response.json();
      setTokens(data);
      data.unshift(  {
        "address": "6W2373i4UWnMUMZHo6te6SZeoP1QfFZAVQCjKBXiHyuZ",
        "chainId": 101,
        "decimals": 9,
        "name": "Lavaburn",
        "symbol": "LBF",
        "logoURI": "/lbf_logo.svg",
        "tags": [
        ],
        "extensions": {
        }
      },)
      setVisibleTokens(data.slice(0, itemsPerPage));
    };

    fetchTokens();
  }, []);

  const fetchMoreData = () => {
    if (visibleTokens.length >= tokens.length) {
      setHasMore(false);
      return;
    }

    // Calculate the end index for the next slice of tokens to show
    const nextEndIndex = visibleTokens.length + itemsPerPage;

    // Fetch more tokens and append to visibleTokens
    setVisibleTokens(prevVisibleTokens =>
      [...prevVisibleTokens, ...tokens.slice(prevVisibleTokens.length, nextEndIndex)]
    );
  };


  return (
    <div className="w-full max-h-[500px] md:h-96 overflow-y-scroll" id="scrollableToken">
      <InfiniteScroll
      dataLength={visibleTokens.length}
      next={fetchMoreData}
      hasMore={hasMore}
      loader={<h4>Loading...</h4>}
      scrollableTarget={"scrollableToken"}
      endMessage={
        <p style={{ textAlign: 'center' }}>
          <b>You have seen it all</b>
        </p>
      }
    >
      {visibleTokens && visibleTokens.map((token) => (
        <div key={token.address} className='flex hover:bg-[white]/10 p-3 space-x-3 rounded-lg cursor-normal select-none' onClick={() => {onSelectToken(token); }}>
          <div className='h-fit'> <img src={token.logoURI} className='rounded-full h-10 my-auto' alt="" /> </div>
          <div>
            <div className='font-bold mb-1'>{token.symbol}</div>
            <div className='text-[white]/40'>{token.name}</div>
          </div>
          <div className="grow"></div>
          <div className='text-[white]/40 text-sm font-mono'>
            {`${token.address.substring(0, 4)}...${token.address.substring(token.address.length - 4)}`}
            <img src="/copy-icon.svg" className='h-4 inline-block mx-1' alt="" />
          </div>
        </div>
      ))}
    </InfiniteScroll>
    </div>
  );
};


interface QuoteResponse {
    inputMint: string;
    inAmount: string;
    outputMint: string;
    outAmount: string;
    otherAmountThreshold: string;
    swapMode: string;
    slippageBps: number;
    platformFee: any;  // 'any' used here due to nullability
    priceImpactPct: string;
    routePlan: Array<{
      swapInfo: {
        ammKey: string;
        label: string;
        inputMint: string;
        outputMint: string;
        inAmount: string;
        outAmount: string;
        feeAmount: string;
        feeMint: string;
      };
      percent: number;
    }>;
    contextSlot: number;
    timeTaken: number;
  }

  interface Token {
    address:string,
    chainId: number,
    decimals: 9,
    name: string,
    symbol: string,
    logoURI: string,
    tags: Array<any>,
    extensions: any
  }

  const list: Array<Token> = [
    {
      "address": "6W2373i4UWnMUMZHo6te6SZeoP1QfFZAVQCjKBXiHyuZ",
      "chainId": 101,
      "decimals": 9,
      "name": "Lavaburn",
      "symbol": "LBF",
      "logoURI": "/lbf_logo.svg",
      "tags": [
      ],
      "extensions": {
      }
    },
    {
      "address": "So11111111111111111111111111111111111111112",
      "chainId": 101,
      "decimals": 9,
      "name": "Solana",
      "symbol": "SOL",
      "logoURI": "/solana.png",
      "tags": [
      ],
      "extensions": {
      }
    }
  ]
  
  interface SwapResponse  {
    tokenOneAmount: string,
    tokenTwoAmount: string
  }

export const Swap = () => {
    const [quote, setQuote] = useState<QuoteResponse | null>(null);
    const [valueOne, setValueOne] = useState("")
    const [valueTwo, setValueTwo] = useState("")
    const [realValueOne, setRealValueOne] = useState(0)
    const [realValueTwo, setRealValueTwo] = useState(0)

    const [tokenOne, setTokenOne] = useState<Token>(list[1])
    const [tokenTwo, setTokenTwo] = useState<Token>(list[0])

    const [showSlippage, setShowSlippage] = useState(false)
    const [customSlippage, setCustomSlippage] = useState(false)
    const [slippage, setSlippage] = useState(0.1)
    const [tokenRatio, flipTokenRatio] = useState(false)
    const [settings, showSettings] = useState(false)

   const wallet = useWallet();
   const connection = useConnection();
    const { publicKey } = useWallet();
    const { showModal, hideModal } = useModal();

    const swapTokens = () => {
        const currentTokenOne = tokenOne;
        const currentTokenTwo = tokenTwo;
        const currentValueOne = valueOne;
        const currentValueTwo = valueTwo;
        const currentRealValueOne = realValueOne;
        const currentRealValueTwo = realValueTwo;
        setTokenOne(currentTokenTwo);
        setTokenTwo(currentTokenOne);
        setValueOne(currentValueTwo);
        setValueTwo(currentValueOne);
        setRealValueOne(currentRealValueTwo);
        setRealValueTwo(currentRealValueOne);
    };

    
    // useEffect(() => {
    //     if (realValueOne) {
    //       getQuote();
    //     }
    //   }, [realValueOne]);

      
    
      const handleChangeOne = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        // Allow only numbers and one decimal point with unlimited decimals following
        if (/^\d*\.?\d*$/.test(newValue)) {
          setValueOne(newValue);
          setRealValueOne(parseFloat(newValue) * (10**tokenOne.decimals));

          if (parseFloat(newValue) == 0 || Number.isNaN(parseFloat(newValue)) || newValue == "") {
            setValueTwo("0");
            setRealValueTwo(0);
          } else {
            getQuote(`${parseFloat(newValue) * (10**tokenOne.decimals)}`);
          }
        }
      };
    
      const handleChangeTwo = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        // Allow only numbers and one decimal point with unlim  ited decimals following
        if (/^\d*\.?\d*$/.test(newValue)) {
          setValueTwo(newValue);
          setRealValueTwo(parseFloat(newValue) * (10**tokenTwo.decimals));

          if (parseFloat(newValue) == 0 || Number.isNaN(parseFloat(newValue))  || newValue == "") {
            setValueOne("0");
            setRealValueOne(0);
          } else {
            getQuote(`${parseFloat(newValue) * (10**tokenTwo.decimals)}`,true);
            // setValueTwo("0");
            // setRealValueTwo(0);
          }
        }
      };

      const changeValueTwo = (newValue:string) => {
        setValueTwo(newValue);
        setRealValueTwo(parseFloat(newValue) * (10**tokenTwo.decimals));

        if (parseFloat(newValue) == 0 || Number.isNaN(parseFloat(newValue))  || newValue == "") {
          setValueOne("0");
          setRealValueOne(0);
        } else {
          getQuote(`${parseInt((parseFloat(newValue) * (10**tokenTwo.decimals)).toFixed(0))}`,true);
          // setValueTwo("0");
          // setRealValueTwo(0);
        }
      }

      const changeValueOne = (newValue:string) => {
        setValueOne(newValue);
        setRealValueOne(parseFloat(newValue) * (10**tokenOne.decimals));

        if (parseFloat(newValue) == 0 || Number.isNaN(parseFloat(newValue))  || newValue == "") {
          setValueTwo("0");
          setRealValueTwo(0);
        } else {
          getQuote(`${parseInt((parseFloat(newValue) * (10**tokenOne.decimals)).toFixed(0))}`);
        }
      }

    const getQuote = async (value:string,second?:boolean) => {
      const url = !second ? 
      `https://quote-api.jup.ag/v6/quote?inputMint=${tokenOne.address}&outputMint=${tokenTwo.address}&amount=${value}&slippageBps=50` : 
      `https://quote-api.jup.ag/v6/quote?inputMint=${tokenTwo.address}&outputMint=${tokenOne.address}&amount=${value}&slippageBps=50`;
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data: QuoteResponse = await response.json();
        setQuote(data);
        if (!second) {
          setValueTwo(`${parseFloat(data.outAmount)/(10**tokenTwo.decimals)}`);
          setRealValueOne(parseFloat(data.outAmount) * (10**tokenTwo.decimals));
        } else {
          setValueOne(`${parseFloat(data.outAmount)/(10**tokenOne.decimals)}`);
          setRealValueOne(parseFloat(data.outAmount) * (10**tokenOne.decimals));
        }
      } catch (error) {
        console.error('Failed to fetch quote:', error);
      }
    };

  
    useEffect(() => {
      document.title = "Swap | Lavaburn"
    }, []);

    useEffect(() => {
      getQuote(`${parseInt((parseFloat(valueOne) * (10**tokenOne.decimals)).toFixed(0))}`)
    }, [tokenTwo]);

    useEffect(() => {
      getQuote(`${parseInt((parseFloat(valueTwo) * (10**tokenTwo.decimals)).toFixed(0))}`)
    }, [tokenTwo]);


    const selectTokenOne = (token:Token) => {
      setTokenOne(token);
      hideModal();
    }

    const selectTokenTwo = (token:Token) => {
      setTokenTwo(token);
      hideModal();
    }

    const getProvider = () => {
      if ("solana" in window) {
        const provider = window.solana;
        return provider;
      } else {
        if ("solflare" in window) {
          const provider = window.solflare;
          return provider;
        } else {
          return undefined;
        }
      }
    }
  

    const swapNow = () => {
    
   fetch('https://quote-api.jup.ag/v6/swap', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      // quoteResponse from /quote api
      quoteResponse: { ...quote },
      // user public key to be used for the swap
      userPublicKey: publicKey?.toBase58(),
      // auto wrap and unwrap SOL. default is true
      wrapAndUnwrapSol: true,
      // feeAccount is optional. Use if you want to charge a fee.  feeBps must have been passed in /quote API.
      // feeAccount: "fee_account_public_key"
    })
  }).then((val) => {val.json().then((vj:any) => {
        // deserialize the transaction
const swapTransactionBuf = Buffer.from(vj.swapTransaction, 'base64');
var transaction = VersionedTransaction.deserialize(swapTransactionBuf);
wallet.wallet?.adapter.sendTransaction(VersionedTransaction.deserialize(swapTransactionBuf),connection.connection)
// connection.connection.
// if (provider) {
//   provider.signAndSendTransaction({}).then((signature:any) => {
//     console.log("Transaction sent with signature:", signature);
//   }).catch((error:any) => {
//     console.error("Transaction failed:", error);
//   });
// } else {
//   console.error("Wallet does not support signAndSendTransaction");
// }

// sign the transaction


// const rawTransaction = transaction.serialize()
// const txid = await connection.sendRawTransaction(rawTransaction, {
//   skipPreflight: true,
//   maxRetries: 2
// });
// await connection.confirmTransaction(txid);
// console.log(`https://solscan.io/tx/${txid}`);
  })})
    }

    const showTokenList = (tk:number) => {
      showModal(
         <div className='max-h-screen md:max-h-auto'>
            <div className="text-3xl py-3 font-bold">Select a token</div>
            <div className='py-4'><input type="text" className='bg-[black]/20 w-full font-mono focus:outline-none rounded-lg p-3' placeholder="Search a token or paste a token address" /></div>
            <div className="py-3">
            <div className='text-[white]/40'>Popular tokens</div>

            </div>
            <div className='text-[white]/40 flex py-2 text-xs px-3'>
              <div>Token</div>
              <div className="grow"></div>
              <div>Address</div>
            </div>
            <div className="max-h-[500px]">
            <TokenList onSelectToken={tk == 0? selectTokenOne : selectTokenTwo} />
            </div>
            
            
            <div className="bg-[white]/10 rounded-lg p-2 text-xs">
            Can’t find the token you’re looking for? Try entering the mint address or check token list settings below.
            </div>
         </div> 
         )
    }

    return (
        <div className='w-full h-fit m-auto pt-[80px]'>
          {/* <div className='mx-auto text-3xl py-6 font-semibold w-fit'>Swap your tokens, <br />on our next level swap. </div> */}
                  <div className='mx-auto md:max-w-xl flex px-4 pt-4'>
                    <div className="grow"></div>
              
                    <div className="rounded-lg cursor-pointer hover:bg-[white]/10 p-2" onClick={() => showModal(<div className={`p-3 rounded-lg`}>
                      <div className="text-3xl font-bold py-4">Settings</div>
                      <div className="text-lg pt-4">Slippage tolerance</div>
                          <div className="flex space-x-2 py-4">
                            <div className={`bg-[white]/10 hover:bg-[white]/20 cursor-pointer rounded-full p-2 px-3 text-xs ${slippage == 0.1 && !customSlippage ? 'border border-[#ef3900]':'border border-transparent'}`} onClick={() => {setSlippage(0.1);setCustomSlippage(false);}}>0.1%</div>
                            <div className={`bg-[white]/10 hover:bg-[white]/20 cursor-pointer rounded-full p-2 px-3 text-xs ${slippage == 0.5 && !customSlippage ? 'border border-[#ef3900]':'border border-transparent'}`} onClick={() => {setSlippage(0.5);setCustomSlippage(false);}}>0.5%</div>
                            <div className={`bg-[white]/10 hover:bg-[white]/20 cursor-pointer rounded-full p-2 px-3 text-xs ${slippage == 1 && !customSlippage? 'border border-[#ef3900]':'border border-transparent'}`} onClick={() => {setSlippage(1);setCustomSlippage(false);}}>1%</div>
                            <div className="grow"></div>
                            <div className='text-xs'>Custom <input type="number" className='w-16 bg-[black]/60 rounded-lg py-2 text-center border-0' value={slippage} onChange={(e) => {setSlippage(parseFloat(e.currentTarget.value));setCustomSlippage(true);}} placeholder='0.0'/> <span className="px-1 text-sm">%</span> </div>
                          </div>
                          {slippage > 1 && <div className={`text-[red] text-sm font-bold py-2 text-center ease-in-out transition rounded-lg p-3 duration-300 ${slippage > 1 ? 'shadow-lg shadow-[red]/10 border border-[red]/20 bg-[red]/5' : ''}`}>Your transaction may be frontrun and result in an unfavorable trade</div>}
                          {/* <div className="text-lg pt-4">Default explorer</div> */}

                        </div>)}><Settings/></div>
                    
                  </div>
                  <div className="mx-auto  mb-4 md:border border-black/30 drop-shadow-xl md:max-w-xl  bg-[#030507]/40 md:rounded-lg p-4 space-y-4 border-y md:border border-[#ef3900]/20">
                  <div className="rounded-lg bg-[#ffffff0C]  shadow-lg shadow-[#ef3900]/10 border border-[#ef3900]/10">
                        <div className='flex bg-[#ef39000c] px-4 py-2'>
                            <div className='text-[#ef3900] text-sm font-semibold mt-1'>From</div>
                            <div className="grow"></div>
                            <TokenBalance token={tokenOne} setValue={changeValueOne} />
                            
                        </div>
                       
                        <div className="flex px-4">
                        <div className="  cursor-pointer transition-all ease-in duration-300 text-2xl cursor-pointer font-bold  space-x-2 pt-3  rounded-lg flex min-w-fit" onClick={ () => {showTokenList(0)} }> <img src={`${tokenOne.logoURI}`} className="w-10 h-10 rounded-full my-auto inline-block" alt="" /> <span className="mt-[3px]">{tokenOne.symbol}</span> <div><img src="/arrow_down.svg" className='h-3 mt-3' alt="" /></div></div>
                        <input type="number"         value={valueOne}
        onChange={handleChangeOne} placeholder='0.0' className="bg-transparent ring-0 focus:outline-none text-right text-3xl grow min-w-5 font-bold" />
                        </div>
                        <div className="flex py-2 px-4">
                        <div className="grow"></div>
                        <TokenPrice token={tokenOne} amount={valueOne}/>
                        </div>
                    </div>
                    <div>
                    </div>
                  </div>
                        <div className="mx-auto rounded-full p-2 border-2 border-[#0f0a0d] bg-gradient-to-r from-[#ed6300] to-[#ef3500] border border-[#ef3900]/30 hover:bg-[#17181d] hover:cursor-pointer relative z-[1000] w-fit mt-[-40px] mb-[-25px]" onClick={() => {
                            swapTokens()
                        }}>
                        <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.88281 18.4384L3.49031 14.0547" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path><path d="M7.88281 3.5625L7.88281 18.4375" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path><path d="M13.1153 3.5625L17.5078 7.94625" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path><path d="M13.1172 18.4375L13.1172 3.5625" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                        </div>
                        <div className="mx-auto border-y md:border border-[#ef3900]/20 mb-4 md:border border-black/30 drop-shadow-xl md:max-w-xl   bg-[#030507]/40 md:rounded-lg mt-4 p-4 space-y-4 " >
                    <div className="rounded-lg bg-[white]/5 ">
                        <div className="flex bg-[#ef39000c] px-4 py-2">
                        <div className='text-[#ef3900] text-sm font-semibold mt-1'>To (estimated)</div>
                        <div className="grow"></div>
                        <TokenBalance token={tokenTwo} setValue={changeValueTwo} />
                        </div>
                        <div className="flex px-4">  
                        <div className="  cursor-pointer transition-all ease-in duration-300 text-2xl cursor-pointer font-bold  space-x-3 py-3  rounded-lg flex min-w-fit" onClick={ () => {showTokenList(1)} }> <img src={`${tokenTwo.logoURI}`} className="w-10 rounded-full h-10 my-auto  inline-block" alt="" /> <span className=" ">{tokenTwo.symbol}</span><div><img src="/arrow_down.svg" className='h-3 mt-2' alt="" /></div></div>
                        <input type="number" value={valueTwo}
        onChange={handleChangeTwo} placeholder='0.0' className="bg-transparent ring-0 focus:outline-none text-right text-3xl font-bold  grow min-w-[0px]" />
                        </div>
                        <div className="flex py-2 px-4">
                        
                        <div className="grow"></div>
                        <div className="text-xs font-bold text-[white]/50"><TokenPrice token={tokenTwo} amount={valueTwo}/></div>
                        </div>

                    </div>
                        <div className="flex px-1 text-sm">
                          {quote && <div className='font-light'>1 {tokenRatio? tokenOne.symbol : tokenTwo.symbol} = {tokenRatio? (parseFloat(valueTwo)/parseFloat(valueOne)) : (parseFloat(valueOne)/parseFloat(valueTwo))} {tokenRatio? tokenTwo.symbol : tokenOne.symbol} <svg className='rotate-90 inline-block cursor-pointer' onClick={() => flipTokenRatio(!tokenRatio)} width="16" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.88281 18.4384L3.49031 14.0547" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path><path d="M7.88281 3.5625L7.88281 18.4375" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path><path d="M13.1153 3.5625L17.5078 7.94625" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path><path d="M13.1172 18.4375L13.1172 3.5625" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                        </div>  }
                        {/* <div className='font-light'>1 {tokenOne.symbol} = {quote} {tokenTwo.symbol}</div> */}
                        <div className="grow"></div>
                        {/* <div className='font-light cursor-pointer' onClick={() => setShowSlippage(!showSlippage) }><span>Slippage: {slippage}% </span><img src="/arrow_down.svg" className='h-2 inline-block mb-1' alt="" /></div> */}
                        </div>
                        <div className={`${showSlippage? 'block' : 'hidden'} transition ease-in-out duration-300`}>
                          
                          </div>
                    {quote &&  <div className="bg-[white]/5 p-3 rounded-lg space-y-2 text-sm">
                        <div className="flex text-[white]/50">
                            <div>Minimum Receive</div>
                            <div className="grow"></div>
                            <div>~{quote && <span>{parseFloat(quote.otherAmountThreshold)/(10**tokenTwo.decimals)}</span> } {tokenTwo.symbol}</div>
                        </div>
                        {/* <div className="flex text-[white]/40">
                            <div>Est. Gas Price</div>
                            <div className="grow"></div>
                            <div>~0.003 SOL</div>
                        </div> */}
                        <div className="flex text-[white]/40">
                            <div>Price Impact</div>
                            <div className="grow"></div>
                            {/* <div className='text-[green] font-bold'>{parseFloat(quote ? ((parseFloat(quote.priceImpactPct || '0') ) * 100).toFixed(2) : "0") }%</div> */}
                            <PriceImpact  priceImpact={quote.priceImpactPct || '0'}/>
                        </div>

                    </div>  }
                    <div>
                    </div>
                  </div>
                    <SwapButton onSwap={swapNow}/>
        </div>
    );
}

interface SwapButtonProps {
  onSwap: () => void
}
const SwapButton : FC<SwapButtonProps> = ({onSwap}) => {
  const { publicKey } = useWallet();
  const {showModal, hideModal} = useModal();
  const [walletModalConfig, setWalletModalConfig] = useState<Readonly<{
    onSelectWallet(walletName: WalletName): void;
    wallets: Wallet[];
}> | null>(null);
const { buttonState, onConnect, onDisconnect, onSelectWallet } = useWalletMultiButton({
  onSelectWallet: setWalletModalConfig,
});

useEffect(() => {
  if (walletModalConfig != null) {
    showModal(<div className='flex-col space-y-3'>
    <span className="text-3xl">Select a wallet</span>
  <div className="py-8 space-y-4">
  {walletModalConfig.wallets.map((wallet) => (
      <button
          key={wallet.adapter.name}
          onClick={() => {
              walletModalConfig.onSelectWallet(wallet.adapter.name);
              hideModal();
              setWalletModalConfig(null);
          }}
          className='p-3 block w-full flex hover:bg-[white]/10 rounded-lg space-x-3'
      >
          <img src={wallet.adapter.icon} className='w-8 h-8' alt="" />
          <span className='my-auto'>{wallet.adapter.name}</span>
      </button>
  ))}
  </div>
  </div>)
  }
}, [walletModalConfig])

  return (<div className="rounded-lg  text-2xl text-center font-bold mt-8 md:w-full bg-gradient-to-r from-[#ed6300] to-[#ef3500] hover:bg-gradient-to-l hover:from-[#ed6300] hover:to-[#ef3500]  cursor-pointer transition-all ease-in duration-300 shadow-lg shadow-[#ef3900]/30 hover:shadow-lg hover:shadow-[#ef3900] py-3 px-8 mx-auto w-[95%] md:w-auto md:max-w-xl" onClick={() => {}} >
  {publicKey && <div onClick={onSwap}><svg width="48" height="48" className='text-white mx-3 inline-block h-8' viewBox="0 0 48 48" fill="white" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.35" d="M42.828 23.1719C41.986 22.3299 32.432 12.7739 29.684 10.0279L32.698 7.0139C33.788 5.9239 33.022 4.0599 31.48 4.0519L20.52 3.9999C19.126 3.9939 17.994 5.1259 18 6.5199L18.052 17.4799C18.06 19.0199 19.924 19.7879 21.014 18.6979L24.028 15.6839C26.776 18.4319 36.33 27.9859 37.172 28.8279C38.734 30.3899 41.266 30.3899 42.828 28.8279C44.39 27.2659 44.39 24.7339 42.828 23.1719Z"></path>
<path d="M5.17199 24.828C6.01399 25.67 15.568 35.226 18.316 37.9721L15.302 40.9861C14.212 42.0761 14.98 43.9401 16.52 43.9481L27.48 44.0001C28.874 44.0061 30.006 42.8741 30 41.4801L29.948 30.5201C29.94 28.9781 28.076 28.212 26.986 29.3021L23.972 32.3161C21.224 29.5681 11.67 20.014 10.828 19.172C9.26599 17.61 6.73399 17.61 5.17199 19.172C3.60999 20.734 3.60999 23.266 5.17199 24.828Z"></path>
</svg>
  Swap</div> }
  {!publicKey && <div onClick={onSelectWallet}>
    
  Connect Wallet</div> }

  </div>    )
}

interface TokenPriceProps {
  token: Token;
  amount: string;
}

interface PriceResponse {
  data: {
    [key: string]: {
      price: number;
    };
  };
}

const TokenPrice: React.FC<TokenPriceProps> = ({ token, amount }) => {
  const [price, setPrice] = useState<number | null>(null);

  useEffect(() => {
    const fetchPrice = async () => {
      try {
        const response = await fetch(`https://price.jup.ag/v4/price?ids=${token.address}`);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data: PriceResponse = await response.json();
        if (data.data[token.address]) {
          setPrice(data.data[token.address].price);
        }
      } catch (error) {
        console.error('Failed to fetch token price:', error);
      }
    };

    fetchPrice();
  }, [token, amount]);

  return (
    <div className="text-xs font-bold text-[white]/50">
      {price && parseFloat(amount) > 0  ? `~ $${(price*parseFloat(amount)).toFixed(2)}` : '~'}
    </div>
  );
};

export default TokenPrice;
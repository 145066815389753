export const Tools = () => {
    return (
                    <div className="flex">
        <div className='w-56 space-y-4'>
{["Swap","Limit orders", "Sniper"].map((val:string) => (
          <div className='py-3 m-2 rounded bg-[#ef3900] text-center'>
          <span className="mx-auto text-[white] font-bold uppercase">{val}</span>
        </div>
))}
        </div>
      <div className="grow mx-8"></div>
        </div>
    );
}
